<template>
  <div class="grid">
    <Toast position="top-center" />
    <Toolbar class="col-12" style="border: 0px; padding: 0rem 0rem 0rem 0.5rem">
      <template #start>
        <Button :label="$t('button.save')" icon="pi pi-save" @click="onSave($event)" :disabled="submitted" />
        <i class="mr-2" />
        <Button v-if="receipt.id" :label="$t('button.undo')" icon="pi pi-replay" @click="onReset($event)" />
        <i class="mr-2" />
        <Button v-if="receipt.id" :label="$t('button.preview')" icon="pi pi-eye" @click="onPreview()" />
      </template>
    </Toolbar>
    <div id="receipt_form" class="p-inputtext-sm md:col-6">
      <div class="card p-fluid">
        <div class="grid field" style="margin-top: 0px">
					<label for="agentCode" class="md:col-3" style="font-weight: bold;">{{$t('partner.name')}}</label>
					<form-field :message="errors.agentCode" class="md:col-9">
            <AutoCompleteValue ref="agentCode" id="agentCode" v-model="receipt.agentCode"
              :options="agents" :required="true" placeholder="" @item-select="onChangeAgent($event)" />
          </form-field>
          <label for="shipmentCodes" class="md:col-3" style="font-weight: bold">{{
              $t("receipt.shipmentCodes")
              }}</label>
          <form-field :message="errors.shipmentCode" class="md:col-9">
            <Dropdown ref="shipmentCodes" id="shipmentCodes" v-model="receipt.shipmentCodes"
              :options="shipments" optionLabel="shipmentCode" optionValue="shipmentCode" :required="true" placeholder="" @change="onChangeShipment($event)"/>
            <!-- <AutoCompleteValue ref="shipmentCode" id="shipmentCode" v-model="receipt.shipmentCodes"
              :options="shipments" :required="true" placeholder=""/> -->
          </form-field>
          <label for="partnerShipmentCode" class="md:col-3" style="font-weight: bold;">{{
            $t("receipt.partnerShipmentCode") }}</label>
          <div id="partnerShipmentCode" class="md:col-3" style="color: red; font-weight: bold; font-size: 18px">
            {{ partnerShipmentCode }}
          </div>
        </div>
        <!-- <div class="grid field" style="margin-top: 0px" v-if="!receipt.id">
          <label for="lastReceiptCode" class="md:col-3" style="font-weight: bold">{{
            $t("receipt.lastReceiptCode") }}</label>
          <div id="lastReceiptCode" class="md:col-3" style="color: red; font-size: 20px">
            {{ lastReceiptCode }}
          </div>
        </div> -->
        <div class="grid field" style="margin-top: 0px">
          <label for="receiptCode" class="md:col-3" style="font-weight: bold;">{{
            $t("receipt.receiptCode")
            }}</label>
          <div class="md:col-9 flex">
            <IconField iconPosition="right">
              <InputText id="receiptCode" type="text" :disabled="true" v-model="receipt.receiptCode"
                style="color: red; font-weight: bold; font-size: 18px" />
                <InputIcon @click="onGetNewReceiptCode" :disabled="!!receipt.receiptCode">
                  <i class="pi pi-refresh" />
                </InputIcon>
            </IconField>
          </div>
          <label for="referenceCode" class="md:col-3" style="font-weight: bold;">{{
            $t("receipt.referenceCode")
            }}</label>
          <div class="md:col-9 flex">
            <InputText id="referenceCode" type="text" v-model="receipt.referenceCode"
              style="color: red" />
          </div>
        </div>
      </div>  
      <div class="card p-fluid" style="margin-top: 10px">
        <h5 style="font-weight: bold">{{ $t("receipt.recipient") }}</h5>
        <div class="grid field" v-if="!receipt.id">
          <label for="recent_recipients" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recentRecipients")
            }}</label>
          <form-field :message="errors.deliveryType" class="md:col-9">
            <Dropdown ref="recent_recipients" id="recent_recipients" v-model="recentRecipient"
              :options="recentRecipients" :optionLabel="previousRecipent" :placeholder="$t('receipt.recentRecipients')"
              @change="onChangeRecentRecipient($event)"/>
          </form-field>
        </div>
        <div class="grid field">
          <label for="recipient" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientName")
            }}</label>
          <form-field :message="errors.recipientName" class="md:col-9">
            <InputText ref="recipientName" id="recipient" v-model="receipt.recipientName" :required="true"
              type="text" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="recipientphone" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientPhone")
            }}</label>
          <form-field :message="errors.recipientPhone" class="md:col-9">
            <IconField iconPosition="right">                
                <InputText ref="recipientPhone" id="recipientphone" type="text" v-model="receipt.recipientPhone"
                :required="true" />
                <InputIcon @click="onSearchRecipient">
                    <i class="pi pi-search" />
                </InputIcon>
            </IconField>
            <!-- <span class="p-input-icon-right">
              <InputText ref="recipientPhone" id="recipientphone" type="text" v-model="receipt.recipientPhone"
                :required="true" />
              <i class="pi pi-search" @click="onSearchRecipient" />
            </span> -->
          </form-field>
        </div>
        <div class="grid field">
          <label for="receiver_province" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientProvince")
            }}</label>
          <form-field :message="errors.recipientProvince" class="md:col-9">
            <AutoCompleteValue ref="recipientProvince" id="recipient_province" v-model="receipt.recipientProvince"
              :options="vnProvinces" :required="true" placeholder="" @item-select="onChangeRecipientProvince($event)" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="recipient_district" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientDistrict")
            }}</label>
          <form-field :message="errors.recipientDistrict" class="md:col-9">
            <AutoCompleteValue ref="recipientDistrict" id="recipient_district" v-model="receipt.recipientDistrict"
              :options="vnDistricts" :required="true" placeholder="" @item-select="onChangeRecipientDistrict($event)" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="recipient_ward" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientWard")
            }}</label>
          <form-field :message="errors.recipientWard" class="md:col-9">
            <AutoCompleteValue ref="recipientWard" id="recipient_ward" v-model="receipt.recipientWard"
              :options="vnWards" :required="true" placeholder="" @item-select="onChangeRecipientWard($event)" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="recipient_address" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientAddress")
            }}</label>
          <form-field :message="errors.recipientAddress" class="md:col-9">
            <Textarea ref="recipientAddress" id="recipient_address" v-model="receipt.recipientAddress" :required="true"
              type="text" rows="2" />
          </form-field>
        </div>
      </div>
      
      <!--
					<div class="card p-fluid">
						<h5>{{ $t('receipt.sender')}}</h5>
						<div class="field grid">
							<label for="sendername" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.senderName')}}</label>
							<form-field class="md:col-5"
								:message="errors.senderName">
								<InputText ref="senderName" id="sendername" type="text" v-model="receipt.senderName" :required="true" autofocus/>
							</form-field>
							<label for="senderphone" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.senderPhone')}}</label>
							<form-field class="md:col-3"
								:message="errors.senderPhone">
								<span class="p-input-icon-right">
									<InputText ref="senderPhone" id="senderphone" v-model="receipt.senderPhone" :required="true" type="text"/>
									<i class="pi pi-search" @click="onSearchSender"/>
								</span>
							</form-field>
						</div>
						<div class="field grid">
							<label for="sender_address" class="col-12 md:col-2">{{$t('receipt.senderAddress')}}</label>
							<div id="sender_address" class="formgroup-inline md:col-10">
								<form-field :message="errors.senderState" class="p-float-label col-12  md:col-4">
									<AutoCompleteValue ref="senderState" id="sender_state"
										v-model="receipt.senderState"
										:options="usStates"
										:required="true"
										placeholder=""
										@item-select="onChangeSenderState($event)"
										:class="{'p-invalid': errors.senderState}"/>
									<label for="sender_state">{{$t('receipt.senderState')}}</label>
								</form-field>
								<form-field :message="errors.senderCounty" class="p-float-label col-12  md:col-4">
									<AutoCompleteValue ref="senderCounty" id="sender_county" v-model="receipt.senderCounty"
										:options="usCounties"
										:required="true"
										placeholder=""
										@item-select="onChangeSenderCounty($event)"/>
									<label for="sender_county">{{$t('receipt.senderCounty')}}</label>
								</form-field>
								<form-field :message="errors.senderCity" class="p-float-label col-12  md:col-4">
									<AutoCompleteValue ref="senderCity" id="sendercity" v-model="receipt.senderCity"
										:options="usCities"
										:required="true"
										placeholder=""
										@item-select="onChangeSenderCity($event)"/>
									<label for="sendercity">{{$t('receipt.senderCity')}}</label>
								</form-field>
							</div>
						</div>
						<div class="field grid">
							<form-field :message="errors.senderAddress" class="p-float-label col-12 col-offset-2 md:col-10">
								<Textarea ref="senderAddress" id="sender_address" v-model="receipt.senderAddress" :required="true" type="text" rows="3"/>
								<label for="sender_address">{{$t('receipt.senderAddress')}}</label>
							</form-field>
						</div>
					</div>

					<div class="card p-fluid">
						<h5>{{ $t('receipt.recipient')}}</h5>
						<div class="field grid">
							<label for="recipient" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.recipientName')}}</label>
							<form-field :message="errors.recipientName" class="col-12 md:col-5">
								<InputText ref="recipientName" id="recipient" v-model="receipt.recipientName" :required="true" type="text" />
							</form-field>
							<label for="recipientphone" class="mb-2 md:col-2 md:mb-0">{{$t('receipt.recipientPhone')}}</label>
							<form-field :message="errors.recipientPhone" class="col-12 md:col-3">
								<span class="p-input-icon-right">
									<InputText ref="recipientPhone" id="recipientphone" type="text" v-model="receipt.recipientPhone" :required="true"/>
									<i class="pi pi-search" @click="onSearchRecipient"/>
								</span>
							</form-field>
						</div>
						<div class="field grid">
							<label for="recipient_address" class="col-12 md:col-2">{{$t('receipt.recipientAddress')}}</label>
							<div id="recipient_address" class="formgroup-inline md:col-10">
								<form-field :message="errors.recipientProvince" class="p-float-label col-12 md:col-4">
									<AutoCompleteValue ref="recipientProvince" id="recipient_province"
										v-model="receipt.recipientProvince"
										:options="vnProvinces"
										:required="true"
										placeholder=""
										@item-select="onChangeRecipientProvince($event)"/>
									<label for="receiver_province">{{$t('receipt.recipientProvince')}}</label>
								</form-field>
								<form-field :message="errors.recipientDistrict" class="p-float-label col-12 md:col-4">
									<AutoCompleteValue ref="recipientDistrict" id="recipient_district"
										v-model="receipt.recipientDistrict"
										:options="vnDistricts"
										:required="true"
										placeholder=""
										@item-select="onChangeRecipientDistrict($event)"/>
									<label for="recipient_district">{{$t('receipt.recipientDistrict')}}</label>
								</form-field>
								<form-field :message="errors.recipientWard" class="p-float-label col-12 md:col-4">
									<AutoCompleteValue ref="recipientWard" id="recipient_ward"
										v-model="receipt.recipientWard"
										:options="vnWards"
										:required="true"
										placeholder=""
										@item-select="onChangeRecipientWard($event)"/>
									<label for="recipient_ward">{{$t('receipt.recipientWard')}}</label>
								</form-field>
							</div>
						</div>
						<div class="field grid">
							<form-field :message="errors.recipientAddress" class="p-float-label col-12 col-offset-2 md:col-10">
								<Textarea ref="recipientAddress" id="recipient_address" v-model="receipt.recipientAddress" :required="true" type="text" rows="3"/>
								<label for="recipient_address">{{$t('receipt.recipientAddress')}}</label>
							</form-field>
						</div>
					</div>
					-->
      <!-- <div class="card p-fluid">
        <div class="grid field">
          <label for="delivery_note" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.delivery_note")
            }}</label>
          <div class="md:col-9">
            <Textarea id="delivery_note" type="text" v-model="receipt.deliveryNote" rows="2" />
          </div>
        </div>
        <div class="grid field">
          <label for="agentNote" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.note")
            }}</label>
          <div class="md:col-9">
            <Textarea id="agentNote" type="text" v-model="receipt.note" rows="2" />
          </div>
        </div>
      </div> -->
    </div>
    <div class="p-inputtext-sm md:col-6">
      
      <!-- <div class="card p-fluid">
        <div class="grid field" style="margin-top: 0px">
          <label for="totalfee" class="md:col-3" style="
              font-weight: bold;
              margin-top: 2px;
              padding-left: 10px;
              padding-right: 5px;
            ">{{ $t("receipt.total") }}</label>
          <div class="md:col-9 flex">
            <span class="p-inputgroup-addon">$</span>
            <InputNumber id="totalFee" v-model="totalFee" mode="decimal" :min="0" :maxFractionDigits="1" disabled
              :inputStyle="{
                color: 'red',
                'font-size': '18px',
                'font-weight': 'bold',
              }" />
          </div>
          <label v-if="showDiscount" for="discountAmount" class="md:col-3" style="
              font-weight: bold;
              margin-top: 2px;
              padding-left: 10px;
              padding-right: 5px;
            ">{{ $t("receipt.discountAmount") }}</label>
          <div v-if="showDiscount" class="md:col-9 flex">
            <span class="p-inputgroup-addon">$</span>
            <InputNumber id="discountAmount" v-model="receipt.discountAmount" mode="decimal" :min="0"
              :maxFractionDigits="1" :inputStyle="{
                color: 'red',
                'font-size': '18px',
                'font-weight': 'bold',
              }" />
          </div>
          <label v-if="showDiscount" for="finalFee" class="md:col-3" style="
              font-weight: bold;
              margin-top: 2px;
              padding-left: 10px;
              padding-right: 5px;
            ">{{ $t("receipt.finalFee") }}</label>
          <div v-if="showDiscount" class="md:col-9 flex">
            <span class="p-inputgroup-addon">$</span>
            <InputNumber id="finalFee" v-model="finalFee" mode="decimal" :min="0" :maxFractionDigits="1" disabled
              :inputStyle="{
                  color: 'red',
                  'background-color':'#90cd93',
                  'font-size': '18px',
                  'font-weight': 'bold',
                }" />
          </div>
        </div>
      </div> -->
      <div class="card p-fluid">
        <div class="grid field">
          <label for="service" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.service")
            }}</label>
          <form-field :message="errors.deliveryType" class="md:col-9">
            <Dropdown ref="deliveryType" id="service" v-model="receipt.deliveryType" :required="true"
              :options="serviceItems" optionLabel="label" optionValue="code" :placeholder="$t('receipt.service')"
              @change="onChangeService($event)"></Dropdown>
          </form-field>
        </div>
        <div class="grid field">
          <label for="packageNumber" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.packageNumber")
            }}</label>
          <form-field :message="errors.packageNumber" class="md:col-9">
            <InputNumber id="packageNumber" ref="packageNumber" :required="true" v-model="receipt.packageNumber" :min="1"
              :max="100" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="weight" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.weight")
            }}</label>
          <!-- <div class="p-inputgroup md:col-4">
            <InputNumber id="weight" v-model="receipt.weight" mode="decimal" @blur="onChangeWeight"/>
            <span class="p-inputgroup-addon">{{$t('unit.lbs')}}</span>
          </div> -->
          <form-field :message="errors.weight" class="md:col-4">
            <InputNumber id="weight" ref="weight" :required="true" v-model="receipt.weight" mode="decimal"
              :minFractionDigits="2" :maxFractionDigits="2" @blur="onChangeWeight" />
          </form-field>
          <div class="flex md:col-5">
            <InputNumber id="weight-kg" v-model="weightKg" mode="decimal" :min="0" :maxFractionDigits="2" disabled />
            <span class="p-inputgroup-addon">{{ $t("unit.kg") }}</span>
          </div>
        </div>
        <div class="grid field">
          <label for="cod" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.cod")
            }}</label>
          <form-field :message="errors.cod" class="md:col-2">
            <Checkbox id="cod" ref="cod" binary v-model="receipt.cod" />
          </form-field>
          <div class="flex md:col-5">
            <InputNumber id="codAmount" v-model="receipt.codAmount" mode="decimal" :min="0" 
              :disabled="!receipt.cod"
              :minFractionDigits="2" :maxFractionDigits="2" />
          </div>  
          <div class="flex md:col-2">
            <Dropdown id="codCurrency" v-model="receipt.codCurrency" :required="true"
              style="width: 100%"
              :options="currencyItems" optionLabel="label" optionValue="code" 
              :placeholder="$t('receipt.codCurrency')"></Dropdown>
          </div>  
        </div>
        <!-- <div class="grid field">
          <label for="unitPrice" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.unitPrice")
            }}</label>
          <form-field :message="errors.unitPrice" class="md:col-9">
            <InputNumber id="unitprice" ref="unitPrice" :required="true" v-model="receipt.unitPrice" mode="currency"
              currency="USD" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="extra_tax" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.extra_tax")
            }}</label>
          <div class="md:col-9">
            <InputNumber id="extra_tax" mode="currency" currency="USD" v-model="receipt.extraTax" />
          </div>
        </div>
        <div class="grid field">
          <label for="oversize" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.oversize_fee")
            }}</label>
          <div class="md:col-9">
            <InputNumber id="oversize" mode="currency" currency="USD" v-model="receipt.oversizeFee"
              :maxFractionDigits="2" />
          </div>
        </div>
        <div class="grid field" v-if="receipt.deliveryType == '1' && enabledRemoteFee">
          <label for="remotefee" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.remote_fee")
            }}</label>
          <div class="md:col-9">
            <small>{{ $t("receipt.selected_remote_address") }}</small>
            <InputNumber id="remotefee" mode="currency" currency="USD" :inputClass="remoteFeeClass"
              v-model="receipt.remoteFee" :min="0" :disabled="receipt.deliveryType == '2' || !enabledRemoteFee" />
            <small class="p-error" v-if="!receipt.remoteFee">{{
              $t("receipt.please_enter_remote_fee")
              }}</small>
          </div>
        </div>
        <div class="grid field">
          <label for="insurance" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.insurance_fee")
            }}</label>
          <div class="md:col-9">
            <InputNumber id="insurance" mode="currency" currency="USD" v-model="receipt.insuranceFee" />
          </div>
        </div>
        <div class="grid field">
          <label for="extraFee" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.extra_fee")
            }}</label>
          <div class="md:col-9">
            <InputNumber id="extraFee" mode="currency" currency="USD" v-model="receipt.extraFee" />
          </div>
        </div> -->
        <!-- <div class="grid field">
          <label for="totalfee" class="md:col-3" style="font-weight:bold;margin-top:10px;">{{$t('receipt.total')}}</label>
          <div class="mt-1 md:col-9 flex" >
            <InputNumber id="totalFee" v-model="totalFee" mode="decimal" :min="0" disabled 
              :inputStyle="{color:'red','font-size':'18px', 'font-weight':'bold'}"/>
            <span class="p-inputgroup-addon">$</span>
          </div>
        </div> -->
        <!-- <div class="grid field">
          <label for="payment_status" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.paymentStatus")
            }}</label>
          <form-field :message="errors.paymentStatus" class="md:col-4">
            <Dropdown ref="paymentStatus" id="payment_status" v-model="receipt.paymentStatus" :required="true"
              :options="paymentStatusItems" optionLabel="label" optionValue="code"
              :placeholder="$t('receipt.paymentStatus')" @change="onChangePaymentStatus($event)">
            </Dropdown>
          </form-field>
          <form-field :message="errors.paymentMethod" class="md:col-5">
            <Dropdown v-if="receipt.paymentStatus == 'paid'" ref="paymentMethod" id="payment_method"
              v-model="receipt.paymentMethod" 
              checkmark
              :required="false" 
              :options="paymentMethodOptions" 
              optionLabel="label"
              optionValue="code" 
              :placeholder="$t('receipt.paymentMethod')" 
              @change="onChangePaymentMethod($event)">
            </Dropdown>
            <div v-if="receipt.paymentStatus == 'unpaid'" className="mt-2">
              <Checkbox v-model="thuho" :binary="true" @input="onChangeThuho" />
              <label for="ingredient2" class="ml-2">{{
                $t("receipt.thuho")
                }}</label>
            </div>
          </form-field>
        </div> -->
        <!-- <div class="grid field">
          <label for="allow_delivery" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.allowDelivery")
            }}</label>
          <form-field :message="errors.allowDelivery" class="md:col-9">
            <Dropdown ref="allowDeliveryOptions" id="allowDelivery" v-model="receipt.allowDelivery" :required="false"
              :options="allowDeliveryOptions" optionLabel="label" optionValue="code"
              :placeholder="$t('receipt.allowDelivery')" @change="onChangeAllowDelivery($event)">
            </Dropdown>
          </form-field>
        </div> -->
        <div class="grid field">
          <label for="internalNode" class="mb-2 md:col-3 md:mb-0">{{
          $t("receipt.note")
        }}</label>
          <div class="md:col-9">
            <Textarea id="internalNode" type="text" v-model="receipt.note" rows="3" />
          </div>
        </div>
      </div>
      <div class="card p-fluid">
        <h5 style="font-weight: bold">{{ $t("receipt.sender") }}</h5>
        <div class="grid field">
          <label for="sendername" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderName")
            }}</label>
          <form-field class="md:col-9" :message="errors.senderName">
            <InputText ref="senderName" id="sendername" type="text" v-model="receipt.senderName"
              autofocus />
          </form-field>
        </div>
        <div class="grid field">
          <label for="senderphone" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderPhone")
            }}</label>
          <form-field class="md:col-9" :message="errors.senderPhone">
            <IconField iconPosition="right">                
                <InputText ref="senderPhone" id="senderphone" type="text" v-model="receipt.senderPhone"
                @change="onChangeSenderPhone($event)"/>
                <InputIcon @click="onSearchSender">
                    <i class="pi pi-search" />
                </InputIcon>
            </IconField>
            <!-- <span class="p-input-icon-right">
              <InputText ref="senderPhone" id="senderphone" v-model="receipt.senderPhone"
                @change="onChangeSenderPhone($event)"
                type="text" />
              <i class="pi pi-search" @click="onSearchSender" />
            </span>-->
          </form-field>
        </div>
        <div class="grid field">
          <label for="senderState" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderState")
            }}</label>
          <form-field :message="errors.senderState" class="md:col-9">
            <AutoCompleteValue ref="senderState" id="sender_state" v-model="receipt.senderState" :options="usStates"
              placeholder="" @item-select="onChangeSenderState($event)" :class="{ 'p-invalid': errors.senderState }" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="senderCounty" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderCounty")
            }}</label>
          <form-field :message="errors.senderCounty" class="p-float-label md:col-9">
            <AutoCompleteValue ref="senderCounty" id="sender_county" v-model="receipt.senderCounty"
              :options="usCounties" placeholder="" @item-select="onChangeSenderCounty($event)" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="sendercity" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderCity")
            }}</label>
          <form-field :message="errors.senderCity" class="p-float-label md:col-9">
            <AutoCompleteValue ref="senderCity" id="sendercity" v-model="receipt.senderCity" :options="usCities"
              placeholder="" @item-select="onChangeSenderCity($event)" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="senderAddress" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderAddress")
            }}</label>
          <form-field :message="errors.senderAddress" class="p-float-label md:col-9">
            <Textarea ref="senderAddress" id="sender_address" v-model="receipt.senderAddress"
              type="text" rows="1" />
          </form-field>
        </div>
        <div class="grid field">
          <label for="delivery_note" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.delivery_note")
          }}</label>
          <div class="md:col-9">
            <Textarea id="delivery_note" type="text" v-model="receipt.deliveryNote" rows="1" />
          </div>
        </div>
      </div>
    </div>
    <Toolbar class="col-12" style="border: 0px; padding: 0rem 0rem 0rem 0.5rem">
      <template #start style="border: 0px; padding-right: 5rem">
        <Button :label="$t('button.save')" icon="pi pi-save" @click="onSave($event)" />
        <i class="mr-2" />
        <Button v-if="receipt.id" :label="$t('button.undo')" icon="pi pi-replay" @click="onReset($event)" />
        <i class="mr-2" />
        <Button v-if="receipt.id" :label="$t('button.preview')" icon="pi pi-eye" @click="onPreview()" />
      </template>
    </Toolbar>
    <div class="col-12">
      <TabView v-if="showPackageTable">
        <TabPanel :header="$t('receipt.manifests')">
          <ReceiptManifestEdit :receipt="receipt" @changeTax="onChangeManifestTax" @saveSuccess="onSaveManifestSuccess"
            @deleteSuccess="onDeleteManifestSuccess" />
        </TabPanel>
        <TabPanel :header="$t('receipt.packages')">
          <CrudTable :entities="receipt.packageDtos" :meta="metaPackageTable" @cell-edit-complete="onCellEditComplete"
            @tableAction="onTableAction" @itemAction="onItemAction" />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import PartnerService from "@/service/PartnerService";
import AddressService from "@/service/AddressService";
import GeoInfoService from "@/service/GeoInfoService";
import ReceiptService from "@/service/ReceiptService";
import PackageService from "@/service/PackageService";
import AutoCompleteValue from "@/components/form/AutoCompleteValue";
import FormField from "@/components/FormField";
import ReceiptManifestEdit from "./ReceiptManifestEdit";
import CrudTable from "@/pages/common/CrudTable";
import { isPositiveNumber } from "@/utils/common";
import { Consts } from "@/utils/consts";
import form from "@/mixins/form";
import AuthService from "@/service/AuthService";
import ShipmentService from "@/service/ShipmentService";
const paymentMethods = [
  { label: "Cash", code: "cash" },
  { label: "Pay in VN", code: "thuho" },
  { label: "Venmo", code: "venmo" },
  { label: "Zelle", code: "zelle" }  
];
const unpaidPaymentMethods = [{ label: "Thu hộ", code: "thuho" }];
const defaultReceipt = {
  deliveryType: "1",
  packageNumber: 1,
  weight: 0,
  unitPrice: 0,
  extraTax: 0,
  oversizeFee: 0,
  remoteFee: 0,
  insuranceFee: 0,
  extraFee: 0,
  allowDelivery: "1",
  cod: false,
  codAmount: 0,
  codCurrency: "usd"
};
export default {
  components: { AutoCompleteValue, FormField, ReceiptManifestEdit, CrudTable },
  mixins: [form],
  // setup() {
  //   const store = useStore();
  //   const user = computed(() => store.state.auth.user);
  //   return {
  //     user,
  //   };
  // },
  data() {
    const allowDeliveryOptions = [
      { label: this.$t("receipt.allowDeliveryOptions.yes"), code: "1" },
      { label: this.$t("receipt.allowDeliveryOptions.no"), code: "0" },
    ];
    return {
      agents: [],
      shipments: [],
      selectedShipment: null,
      originalEntity: {},
      receipt: {...defaultReceipt},
      recentRecipients: [],
      recentRecipient: null,
      lastReceiptCode: null,
      partnerShipmentCode: null,
      submitted: false,
      errors: {},
      weightKg: null,
      enabledRemoteFee: false,
      serviceItems: [
        { label: this.$t("receipt.serviceOptions.door2door"), code: "1" },
        { label: this.$t("receipt.serviceOptions.pickatoffice"), code: "2" },
      ],
      currencyItems: [
        { label: this.$t("unit.usd"), code: "usd" },
        { label: this.$t("unit.vnd"), code: "vnd" },
      ],
      selectedService: null,
      paymentStatusItems: [
        { label: this.$t("receipt.paymentStatuses.paid"), code: "paid" },
        { label: this.$t("receipt.paymentStatuses.unpaid"), code: "unpaid" },
      ],
      paymentMethodOptions: paymentMethods,
      thuho: true,
      allowDeliveryOptions,
      usGeoInfos: {},
      vnGeoInfos: {},
      usStates: [],
      usCounties: [],
      usCities: [],
      vnProvinces: [],
      vnDistricts: [],
      vnWards: [],
      selectedPaymentStatus: null,
      showPackageTable: false,
      metaPackageTable: {
        entityName: "package",
        expander: false,
        editMode: "cell",
        paginator: {
          enable: true,
          rows: 10,
        },
        columns: [
          // {
          //   name: "receiptCode",
          //   headerStyle: "width:15%; min-width:10rem;",
          // },
          {
            name: "packageCode",
            headerStyle: "width:15%; min-width:10rem;",
          },
          {
            name: "weightLbs",
            headerStyle: "width:15%; min-width:10rem;",
            editor: {
              enable: true,
            },
          },
          {
            name: "deleted",
            label: "common.deleted",
            headerStyle: "width:15%; min-width:10rem;",
            editor: {
              enable: false,
            },
          },
          {
            name: "description",
            headerStyle: "min-width:10rem;",
            editor: {
              enable: true,
            },
          },
        ],
        tableActions: [
          /*
            {
              name: "create",
              icon: "pi pi-plus",
              style: "p-button-primary mr-2"
            },
            {
              name: "packageedit",
              icon: "pi pi-pencil",
              style: "p-button-primary mr-2"
            },
            */
        ],
        itemActions: [
          /*
            {
              name: "edit",
              icon: "pi pi-pencil",
              style: "p-button-rounded p-button-success mr-2"
            },
            {
              name: "delete",
              icon: "pi pi-trash",
              style: "p-button-rounded p-button-warning mr-2"
            },
            {
              name: "qrcode",
              icon: "pi pi-qrcode",
              style: "p-button-rounded p-button-primary mr-2"
            }
            */
        ],
        metaSubTable: {
          entityName: "packageItem",
          selectionMode: "multiple",
          paginator: {
            enable: false,
          },
          tableActions: [
            {
              name: "create",
              icon: "pi pi-plus",
              style: "p-button-rounded p-button-success mr-2",
            },
          ],
          columns: [
            {
              name: "name",
              headerStyle: "width:20%; min-width:10rem;",
            },
            {
              name: "quantity",
              headerStyle: "width:20%; min-width:10rem;",
            },
          ],
          itemActions: [
            {
              name: "edit",
              icon: "pi pi-pencil",
              style: "p-button-rounded p-button-success mr-2",
            },
            {
              name: "delete",
              icon: "pi pi-trash",
              style: "p-button-rounded p-button-warning mt-2",
            },
          ],
        },
      },
    };
  },
  async mounted() {
    this.loadAgents();
    this.$watch(
      () => this.$props.receipt,
      async (params) => {
        console.log(params);
        await this.loadReceipt(params.id);
        //console.log('route path has changed from ' +from+' to '+to )
      }
    );
    this.$watch(
      () => this.$route.params,
      async (params) => {
        await this.loadReceipt(params.id);
        //console.log('route path has changed from ' +from+' to '+to )
      }
    );
    await this.loadReceipt(this.$route.params.id);
    await this.loadGeoInfos();
  },
  computed: {
    showDiscount() {
      const user = AuthService.currentUser || {};
      return user.orgType == 'owner' || user.organization == 'P';
    },
    totalFee() {
      let totalFee = 0;
      if (this.receipt.insuranceFee > 0) {
        totalFee += this.receipt.insuranceFee;
      }
      if (this.receipt.extraTax > 0) {
        totalFee += this.receipt.extraTax;
      }
      if (this.receipt.extraFee > 0) {
        totalFee += this.receipt.extraFee;
      }
      if (this.receipt.oversizeFee > 0) {
        totalFee += this.receipt.oversizeFee;
      }
      if (this.receipt.weight > 0 && this.receipt.unitPrice > 0) {
        totalFee += this.receipt.weight * this.receipt.unitPrice;
      }
      if (this.receipt.remoteFee > 0) {
        totalFee += this.receipt.remoteFee;
      }
      return totalFee;
    },
    finalFee() {
      if (this.receipt.discountAmount >= 0) {
        return this.totalFee - this.receipt.discountAmount;
      }
      return this.totalFee;
    },
    invalidSenderName() {
      return this.submitted && !this.receipt.senderName;
    },
    remoteFeeClass() {
      return this.enabledRemoteFee && this.receipt.deliveryType == "1"
        ? "highlightRemoteFee"
        : "";
    },
  },
  methods: {
    previousRecipent(recipient) {
      let parts = [];
      if (recipient.recipientPhone) {
        parts.push(recipient.recipientPhone);
      }
      if (recipient.recipientName) {
        parts.push(recipient.recipientName);
      }
      if (recipient.recipientProvince) {
        parts.push(recipient.recipientProvince);
      }
      return parts.join('-');
    },
    async loadAgents() {
      const agents = await PartnerService.getAgents();
      this.agents = agents.map(agent => agent.code);
    },
    async onChangeAgent(event) {
      this.shipments = [];
      this.lastReceiptCode = null;
      const shipments = await ShipmentService.getAgentShipments(event.value);
      if (!this.receipt.id) {
        this.receipt.receiptCode = await ReceiptService.getNextCodeByAgent(event.value);
      }
      this.shipments = shipments;
      if (this.receipt.shipmentCodes) {
        this.$refs.shipmentCodes.value = this.receipt.shipmentCodes;
      } else if (this.shipments.length == 1) {
        this.receipt.shipmentCodes = this.shipments[0];
        this.partnerShipmentCode = this.shipments[0].referenceCode;
        console.log(this.partnerShipmentCode);
      }
    },
    async onChangeShipment(event) {
      for (let shipment of this.shipments) {
        if (shipment.shipmentCode == event.value) {
          this.selectedShipment = shipment;
          this.receipt.shipmentId = shipment.id;
          this.partnerShipmentCode = shipment.referenceCode;
          break;
        }
      }
    },
    async loadReceipt(receiptId) {
      if (receiptId) {
        this.receipt.id = receiptId;
        this.showPackageTable = true;
        let receipt = await ReceiptService.get(receiptId);
        this._setReceipt(receipt);
        await this.onChangeAgent({value: this.receipt.agentCode});
      } else {
        this.showPackageTable = false;
        //this.lastReceiptCode = await ReceiptService.getLastReceiptCode();
        this.originalEntity = {};
        this.receipt = {...defaultReceipt};
      }
    },
    _setReceipt(receipt) {
      this.originalEntity = receipt;
      this.partnerShipmentCode = receipt.shipmentDto?.referenceCode;
      this.receipt = { ...this.originalEntity };
      this.weightKg = receipt.weight ? receipt.weight * Consts.POUND2KG : null;
    },
    async onGetNewReceiptCode() {
      if (this.receipt.agentCode) {
        this.receipt.receiptCode = await ReceiptService.getNextCodeByAgent(this.receipt.agentCode);
      }
    },
    async loadGeoInfos() {
      let usGeoinfo = GeoInfoService.getByCountry("us");
      let vnGeoinfo = GeoInfoService.getByCountry("vn");
      let firstLevelGeoInfo = GeoInfoService.loadFirstLevel(["us", "vn"]);
      let geoInfos = await Promise.all([
        usGeoinfo,
        vnGeoinfo,
        firstLevelGeoInfo,
      ]);
      this.usGeoInfos = geoInfos[0];
      this.vnGeoInfos = geoInfos[1];
      let res = geoInfos[2];
      this.usStates = res["us"];
      this.vnProvinces = res["vn"];
      if (this.receipt.senderState) {
        this._setSenderState(this.receipt.senderState);
      }
      if (this.receipt.senderCounty) {
        this._setSenderCounty(this.receipt.senderCounty);
      }
      if (this.receipt.senderCity) {
        this._setSenderCity(this.receipt.senderCity);
      }
      if (this.receipt.recipientProvince) {
        this._setRecipientProvince(this.receipt.recipientProvince);
      }
      if (this.receipt.recipientDistrict) {
        this._setRecipientDistrict(this.receipt.recipientDistrict);
      }
      if (this.receipt.recipientWard) {
        this._setRecipientWard(this.receipt.recipientWard);
      }
    },
    onBack() {
      this.$router.back();
    },
    onPreview() {
      this.$router.push({
        name: "print_receipt",
        params: { id: this.receipt.id },
      });
    },
    onSearchSender() {
      if (this.receipt.senderPhone) {
        AddressService.findByPhoneNumber(
          this.receipt.senderPhone,
          "sender"
        ).then((response) => {
          const data = response.content;
          if (Array.isArray(data) && data.length > 0) {
            let receipt = this.receipt;
            receipt.senderName = data[0].contactName;
            if (typeof data[0].state == "string") {
              this._setSenderState(data[0].state);
            }
            if (typeof data[0].county == "string") {
              this._setSenderCounty(data[0].county);
            }
            if (typeof data[0].city == "string") {
              this._setSenderCity(data[0].city);
            }
            receipt.senderAddress = data[0].detail;
            this.receipt = receipt;
          }
        });
      }
    },
    _setSenderState(value) {
      if (Array.isArray(this.usStates)) {
        let lcValue = value.trim().toLowerCase();
        for (let i = 0; i < this.usStates.length; i++) {
          if (this.usStates[i].toLowerCase().indexOf(lcValue) >= 0) {
            this.receipt.senderState = this.usStates[i];
            if (!this.receipt.id) {
              this.receipt.senderCounty = null;
              this.receipt.senderCity = null;
            }
            break;
          }
        }
      }
      if (this.usGeoInfos && this.receipt.senderState) {
        const counties = this.usGeoInfos[this.receipt.senderState];
        if (typeof counties == "object") {
          this.usCounties = Object.keys(counties);
        }
      }
    },
    _setSenderCounty(value) {
      if (Array.isArray(this.usCounties)) {
        let lcValue = value.trim().toLowerCase();
        for (let i = 0; i < this.usCounties.length; i++) {
          if (this.usCounties[i].toLowerCase().indexOf(lcValue) >= 0) {
            this.receipt.senderCounty = this.usCounties[i];
            if (!this.receipt.id) {
              this.receipt.senderCity = null;
            }
            break;
          }
        }
      }
      if (
        this.usGeoInfos &&
        this.receipt.senderState &&
        this.receipt.senderCounty
      ) {
        const counties = this.usGeoInfos[this.receipt.senderState];
        const cities = counties[this.receipt.senderCounty];
        if (typeof cities == "object") {
          this.usCities = Object.keys(cities);
        }
      }
    },
    _setSenderCity(value) {
      if (Array.isArray(this.usCities)) {
        let lcValue = value.trim().toLowerCase();
        for (let i = 0; i < this.usCities.length; i++) {
          if (this.usCities[i].toLowerCase().indexOf(lcValue) >= 0) {
            this.receipt.senderCity = this.usCities[i];
            break;
          }
        }
      }
    },
    onSearchRecipient() {
      if (this.receipt.recipientPhone) {
        AddressService.findByPhoneNumber(
          this.receipt.recipientPhone,
          "recipient"
        ).then((response) => {
          const data = response.content;
          if (Array.isArray(data) && data.length > 0) {
            this.receipt.recipientName = data[0].contactName;
            this._setRecipientProvince(data[0].province);
            this._setRecipientDistrict(data[0].district);
            this._setRecipientWard(data[0].ward);
            this.receipt.recipientAddress = data[0].detail;
          }
        });
      }
    },
    _setRecipientProvince(value) {
      if (typeof value == "string" && Array.isArray(this.vnProvinces)) {
        let lcValue = value.trim().toLowerCase();
        for (let i = 0; i < this.vnProvinces.length; i++) {
          if (this.vnProvinces[i].toLowerCase().indexOf(lcValue) >= 0) {
            this.receipt.recipientProvince = this.vnProvinces[i];
            if (!this.receipt.id) {
              this.receipt.recipientDistrict = null;
              this.receipt.recipientWard = null;
            }
            break;
          }
        }
      }
      if (this.vnGeoInfos && this.receipt.recipientProvince) {
        const districts = this.vnGeoInfos[this.receipt.recipientProvince];
        if (districts) {
          this.vnDistricts = Object.keys(districts);
        } else {
          this.vnDistricts = [];
        }
      }
      this.enabledRemoteFee = false;
    },
    _setRecipientDistrict(value) {
      if (typeof value == "string" && Array.isArray(this.vnDistricts)) {
        let lcValue = value.trim().toLowerCase();
        for (let i = 0; i < this.vnDistricts.length; i++) {
          if (this.vnDistricts[i].toLowerCase().indexOf(lcValue) >= 0) {
            this.receipt.recipientDistrict = this.vnDistricts[i];
            if (!this.receipt.id) {
              this.receipt.recipientWard = null;
            }
            break;
          }
        }
      }
      if (
        this.vnGeoInfos &&
        this.receipt.recipientProvince &&
        this.receipt.recipientDistrict
      ) {
        let districts = this.vnGeoInfos[this.receipt.recipientProvince];
        this.vnWards = Object.keys(districts[this.receipt.recipientDistrict]);
      }
      this.enabledRemoteFee = false;
    },
    _setRecipientWard(value) {
      if (typeof value == "string" && Array.isArray(this.vnWards)) {
        let lcValue = value.trim().toLowerCase();
        for (let i = 0; i < this.vnWards.length; i++) {
          if (this.vnWards[i].toLowerCase().indexOf(lcValue) >= 0) {
            this.receipt.recipientWard = this.vnWards[i];
            break;
          }
        }
      }
      if (
        this.vnGeoInfos &&
        this.receipt.recipientProvince &&
        this.receipt.recipientDistrict &&
        this.receipt.recipientWard
      ) {
        const districts = this.vnGeoInfos[this.receipt.recipientProvince];
        const wards = districts[this.receipt.recipientDistrict];
        //this.receipt.remoteFee = wards[this.receipt.recipientWard] ? Consts.REMOTE_FEE_RATE : 0
        this.enabledRemoteFee = wards[this.receipt.recipientWard];
        /*
        if (this.enabledRemoteFee) {
          this.$toast.add({
            severity: "info",
            summary: "Info",
            detail: this.$t("receipt.remote_address_selected"),
            life: 3000,
          });
        }
        */
      }
    },
    onChangeManifestTax(value) {
      console.log(value);
      if (this.receipt) {
        this.receipt.extraTax = value; //(this.receipt.extraTax || 0) + value;
      } else {
        this.receipt = {
          extraTax: value,
        };
      }
    },
    onSaveManifestSuccess() {
      this.loadReceipt(this.$route.params.id);
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: this.$t("receipt.save_manifest_successful"),
        life: 3000,
      });
    },
    onDeleteManifestSuccess() {
      this.loadReceipt(this.$route.params.id);
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: this.$t("receipt.delete_manifest_successful"),
        life: 3000,
      });
    },
    onChangeWeight(event) {
      try {
        let value = parseFloat(event.value);
        this.weightKg = value ? value * Consts.POUND2KG : null;
      } catch (e) {
        console.debug(3);
      }
    },
    onChangeService(event) {
      this.receipt.deliveryType = event.value;
      if (this.receipt.deliveryType == "2") {
        this.receipt.remoteFee = 0;
      }
    },
    async onChangeSenderPhone(event) {
      this.recentRecipients = await ReceiptService.getRecentRecipients(event.target.value);
    },
    onChangeSenderState(event) {
      this.receipt.senderState = event.value;
      this._setSenderState(event.value);
    },
    onChangeSenderCounty(event) {
      this.receipt.senderCounty = event.value;
      this._setSenderCounty(event.value);
    },
    loadSenderCounty() {
      this._setSenderCounty(this.receipt.senderCounty);
    },
    onChangeSenderCity(event) {
      this.receipt.senderCity = event.value;
      this._setSenderCity(event.value);
    },
    onChangeRecentRecipient(event) {
      let recipient = event.value; 
      if (recipient) {
        this.receipt.recipientName = recipient.recipientName;
        this.receipt.recipientPhone = recipient.recipientPhone;
        this.receipt.recipientProvince = recipient.recipientProvince;
        this.receipt.recipientDistrict = recipient.recipientDistrict;
        this.receipt.recipientWard = recipient.recipientWard;
        this.receipt.recipientAddress = recipient.recipientAddress;
      }
    },
    onChangeRecipientProvince(event) {
      this._setRecipientProvince(event.value);
    },
    loadRecipientDistrict() {
      if (
        this.vnDistricts.length == 0 &&
        this.vnProvinces.length > 0 &&
        this.receipt.recipientProvince
      ) {
        this._setRecipientProvince(this.receipt.recipientProvince);
      }
    },
    onChangeRecipientDistrict(event) {
      this._setRecipientDistrict(event.value);
    },
    onChangeRecipientWard(event) {
      this._setRecipientWard(event.value);
    },
    onChangePaymentStatus(event) {
      this.receipt.paymentStatus = event.value;
      if (this.receipt.paymentStatus == "unpaid") {
        this.receipt.paymentMethod = "thuho";
        this.receipt.allowDelivery = null;
        this.paymentMethodOptions = unpaidPaymentMethods;
      } else {
        this.paymentMethodOptions = paymentMethods;
        this.receipt.allowDelivery = "1";
      }
    },
    onChangeThuho(thuho) {
      if (thuho) {
        this.receipt.paymentMethod = "thuho";
      } else {
        this.receipt.paymentMethod = null;
      }
    },
    onChangePaymentMethod(event) {
      this.receipt.paymentMethod = event.value;
    },
    onChangeAllowDelivery(event) {
      this.receipt.allowDelivery = event.value;
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
        case "weightLbs":
          if (isPositiveNumber(newValue)) {
            data[field] = newValue;
            PackageService.updateField(data.id, field, newValue);
          } else {
            event.preventDefault();
          }
          break;

        default:
          if (newValue.trim().length > 0) {
            data[field] = newValue;
            PackageService.updateField(data.id, field, newValue);
          } else {
            event.preventDefault();
          }
          break;
      }
    },
    async onSave() {
      if (!this.submitted) {
        this.errors = this.validate(this.receipt, "receipt");
        if (this.receipt.paymentStatus == "paid") {
          this.receipt.allowDelivery = "1";
        } else if (
          this.receipt.paymentStatus == "unpaid" &&
          !this.receipt.allowDelivery
        ) {
          this.errors.allowDelivery = this.$t("receipt.required_allowDelivery");
        }
        //Remote fee
        // if (this.enabledRemoteFee && this.receipt.deliveryType == '1' && !this.receipt.remoteFee) {
        // 	this.errors.remoteFee = this.$t("receipt.required_remoteFee")
        // }
        if (Object.keys(this.errors).length == 0) {
          this.submitted = true;
          //this.receipt.remoteFee = this.receipt.remoteFee / 100;
          this.receipt.totalFee = this.totalFee;
          if (this.receipt.id) {
            try { 
              const response = await ReceiptService.update(this.receipt.id, this.receipt)
              console.log(response);
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: this.$t("receipt.updated"),
                life: 3000,
              });
              this.submitted = false;
              this.$router.push({
                name: "users_receipt_view",
                params: { id: this.receipt.id },
              });
            } catch (e) {
              console.log(e);
            }
          } else {
            try {
              const response = await ReceiptService.create(this.receipt)
              if (response.errorCode == 0) {
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: this.$t("receipt.created"),
                  life: 3000,
                });
                this.$router.push({
                  name: "users_receipt_view",
                  params: { id: response.data.id },
                });
                this.submitted = false;
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: this.$t(response.message, this.receipt),
                  life: 3000,
                });
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
    },
    onReset() {
      this.receipt = { ...this.originalEntity };
    },
    onTableAction(action) {
      if (action == "create") {
        console.log("Call packageEdit");
      } else if (action == "packageedit") {
        this.$router.push({
          name: "users_receipt_package",
          params: { receiptId: this.receipt.id },
        });
      } else if (action == "delete") {
        console.log("Call packageDelete");
      }
    },
    onItemAction(action, entity) {
      if (action == "edit") {
        console.log("Call packageEdit");
        console.log(entity);
        this.$router.push({
          name: "users_receipt_package",
          params: { receiptId: this.receipt.id, packageId: entity.id },
        });
      } else if (action == "delete") {
        console.log("Call packageDelete");
        console.log(entity);
      } else if (action == "qrcode") {
        console.log(entity);
      }
    },
    initManifestFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.narrow .p-toolbar {
  padding: 0 1rem;
  margin: -0.5rem 0;
  border: 0;
  font-weight: 600;
}

.info .grid {
  padding-top: 3px;
}

.field.grid>label {
  align-items: center;
}

.field {
  margin-bottom: 0;
}

.highlightRemoteFee {
  border: 1px solid #dbdb22;
}
</style>
